<template>
    <div>
        <div v-if="typebusiness=='bloodtestlaboratory' || typebusiness=='medicalclinic'">
            <div class="d-flex justify-space-around my-3">
                <h5 class="mx-3" ><b>ID:</b> {{ customerToShow.ID_Customer }}</h5>
                <h5 class="mx-3" ><b>Cliente:</b> {{customerToShow.FirstName}} {{customerToShow.LastName}}</h5>
            </div>

      <v-divider></v-divider>

      <div class="patients-to-client">
        <div style="display: flex; justify-content: space-between">
          <h2 class="mb-4">Pacientes vinculados al cliente</h2>
          <!--v-btn  class="text-uppercase btnclean" @click.prevent="getPatients()">
                    Recargar pacientes
                </v-btn-->
        </div>
        <br />
        <GeneralFilter
          entity="lista"
          :filterEndpoint="$API.patients.getPatientsFiltered"
          :search="search"
          @emptyFilter="getPatients"
          @filtered="filterHandlerPatients"
        >
          <DxDataGrid class="isScrolledByGeneralFilter"
            id="gridContainer"
            :data-source="patients"
            :show-borders="true"
            key-expr="ID_Patient"
          >
            <DxPaging :page-size="10" />
            <DxScrolling row-rendering-mode="virtual" />
            <DxColumn data-field="ID_Patient" caption="ID" />
            <DxColumn data-field="FirstName" caption="Nombre" />
            <DxColumn data-field="LastName" caption="Apellido" />
            <DxColumn data-field="NotificationMobile" caption="Télefono" />
            <DxColumn
              data-field="NotificationEmail"
              caption="Correo eléctronico"
            />
            <!--DxColumn data-field="Gender">
                        <DxLookup
                                :data-source="gender"
                                display-expr="Name"
                                value-expr="ID"
                        />
                    </DxColumn-->
            <!--DxColumn data-field="DateOfBirth"
                            caption="Birth Date"
                            data-type="date"/-->

            <!--DxColumn data-field="Age"/-->
            <DxColumn data-field="Address" caption="Dirección" />
            <!--DxColumn data-field="City"/>
                    <DxColumn data-field="State"/-->
            <DxColumn data-field="Country" caption="País" />
            <DxColumn :width="200" data-field="OldID" caption="Código alterno"></DxColumn> 
            <DxColumn
              :width="80"
              cell-template="show-template"
              caption=""
              v-if="$store.getters['rolesUser/getCustomRole'](84)"
            ></DxColumn>
            <template #show-template="{ data }">
              <a href="#" class="mx-1" @click="patientSelected(data)">
                <v-icon color="primary">mdi-eye</v-icon>
              </a>
            </template>
          </DxDataGrid>
        </GeneralFilter>

        <div class="d-flex justify-center text-center" style="gap: 50px;">
          <v-btn class="btnsave" v-if="$store.getters['rolesUser/getCustomRole'](83)" large @click.prevent.stop="addPatients()">
            Añadir nuevo paciente
          </v-btn>
          <v-btn class="btnsave" v-if="$store.getters['rolesUser/getCustomRole'](83)" large @click.prevent.stop="handleShowCustomersList('medical')">
            Migrar pacientes
          </v-btn>
        </div>
      </div>
    </div>

    <!--VETERINARY PATIENTS-->
    <div v-if="typebusiness == 'veterinary'">
      <div class="d-flex justify-space-around my-3">
        <h3 class="mx-3">ID de Cliente: {{ customerToShow.ID_Customer }}</h3>
        <h3 class="mx-3">
          Nombre de Cliente: {{ customerToShow.FirstName }}
          {{ customerToShow.LastName }}
        </h3>
      </div>

      <v-divider></v-divider>

      <div class="patients-to-client">
        <p class="mb-4">Pacientes de la veterinaria vinculado al cliente</p>
        <div class="d-flex justify-end" style="gap: 50px;">
          <div class="text-center">
            <v-btn
              color="btnsave"
              dark
              large
              v-if="$store.getters['rolesUser/getCustomRole'](83)"

              @click.prevent.stop="addVeterinaryPatients()"
            >
              Añadir nuevo paciente
            </v-btn>
          </div>
          <div class="text-center">
            <v-btn
              color="btnsave"
              dark
              large
              v-if="$store.getters['rolesUser/getCustomRole'](83)"
              @click.prevent.stop="handleShowCustomersList('veterinary')"
            >
              Migrar pacientes
            </v-btn>
          </div>
        </div>
        <br /><br />
        <v-btn
          dark
          color="light-blue darken-1"
          class="text-uppercase"
          @click.prevent="getVeterinaryPatients()"
        >
          Recargar pacientes
        </v-btn>

        <br />
        <GeneralFilter
          entity="lista"
          :filterEndpoint="$API.veterinarypatients.getPatientsFiltered"
          :search="search"
          @emptyFilter="getVeterinaryPatients"
          @filtered="filterHandlerVeterinaryPatients"
        >
          <DxDataGrid class="isScrolledByGeneralFilter"
            id="gridContainer"
            :data-source="veterinarypatients"
            :show-borders="true"
            key-expr="ID_Patient"
            @editing-start="logEvent('EditingStart')"
            @init-new-row="logEvent('InitNewRow')"
            @row-inserting="logEvent('RowInserting')"
            @row-inserted="logEvent('RowInserted')"
            @row-removing="logEvent('RowRemoving')"
            @row-removed="rowRemoved($event)"
            @saving="logEvent('Saving')"
            @saved="dataSaved"
          >
            <DxPaging :enabled="false" />

            <DxColumn
              :width="80"
              cell-template="show-template"
              caption=""
            v-if="$store.getters['rolesUser/getCustomRole'](84)"

            ></DxColumn>
            <DxColumn data-field="ID_Patient" caption="ID" />
            <DxColumn data-field="NamePatient" caption="Paciente" />
            <DxColumn data-field="Gender" caption="Sexo" />
            <DxColumn data-field="Species" caption="Especíe" />
            <DxColumn data-field="Breed" caption="Raza" />
            <DxColumn
              data-field="NotificationMobile"
              caption="Número del cliente"
            />
            <DxColumn
              data-field="NotificationEmail"
              caption="Correo del cliente"
            />
            <DxColumn data-field="OldID" caption="Código alterno"></DxColumn> 
            <DxColumn
            v-if="$store.getters['rolesUser/getCustomRole'](85)"
              :width="60"
              cell-template="show-delete"
              caption=""
            ></DxColumn>
            <template #show-template="{ data }">
              <a href="#" class="mx-1" @click="veterinaryPatientSelected(data)">
                <v-icon color="primary">mdi-eye</v-icon>
              </a>
            </template>
            <template #show-delete="{ data }">
              <a href="#" class="mx-1" @click="confirmDelete(data)">
                <v-icon color="error">mdi-delete</v-icon>
              </a>
            </template>
          </DxDataGrid>
        </GeneralFilter>
      </div>
    </div>
    <v-dialog v-model="showCustomersList" width="80%">
      <v-card flat class="pa-1" rounded="lg">
        <v-card-text class="pa-0">
          <GeneralFilter  class="background border-box" :filterEndpoint="$API.customers.filterCustomers" :search="{...searchCustomers, filteredFrom: 0 }" @emptyFilter="getAllCustomers" @filtered="filterHandler">
            <DxDataGrid class="isScrolledByGeneralFilter" :data-source="customers" key-expr="ID_Customer" :show-row-lines="true"
              :show-borders="true" :row-alternation-enabled="true">
  
              <DxScrolling row-rendering-mode="virtual" />
              <DxPaging :page-size="10" />
              <DxColumn :width="80" cell-template="select-template" v-if="$store.getters['rolesUser/getCustomRole'](78)"
               caption=""></DxColumn>
              <DxColumn data-field="ID_Customer" caption="ID"></DxColumn>
              <DxColumn data-field="customerNameToShow" caption="Nombre"></DxColumn>
              <DxColumn data-field="customerEmailToShow" caption="Correo"></DxColumn> 
              <DxColumn data-field="LegalName" caption="Nombre Legal"></DxColumn> 
              <template #select-template="{ data }">
                <a class="mx-1" @click="customerSelected(data.data.ID_Customer)">
                  <v-icon color="primary">mdi-swap-horizontal-circle</v-icon>
                </a>
              </template>  
            </DxDataGrid>
          </GeneralFilter>
        </v-card-text>
      </v-card>
    </v-dialog>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
  </div>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter";
import Alerts from "@/components/Alerts";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  //DxEditing,
  DxPaging,
} from "devextreme-vue/data-grid";
import { bus } from "../../../main";
import { mapState } from "vuex";
export default {
  name: "Patients",
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    //  DxEditing,
    Alerts,
    DxPaging,
    GeneralFilter,
  },
  props: ["customerToShow", "win"],
  watch: {
    customerToShow: (newVal, oldVal) => {
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
  data() {
    return {
      typebusiness: null,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      /*For bloodtestlaboratory*/
      addPatient: false,
      patients: [],
      Confirmdata: {},
      gender: [
        {
          ID: "male",
          Name: "Male",
        },
        {
          ID: "female",
          Name: "Female",
        },
      ],
      selectTextOnEditStart: true,
      startEditAction: "click",

      /*For veterinary */
      veterinarypatients: {},

      showCustomersList: false,
      customers: [],
      searchCustomers: {},
      customerToMigrate: null,

      /*For filter*/
      search: {
        filter: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    bus.$on("loadIt", () => {
      this.getVeterinaryPatients();
    });
    this.$emit("reloadData");
    this.typebusiness =
      JSON.parse(localStorage.getItem("user")).businesstype || "";

    if (this.typebusiness == "veterinary") {
      this.getVeterinaryPatients();
    }

            if(this.typebusiness == "bloodtestlaboratory" || this.typebusiness == "medicalclinic"){
                this.getPatients()
            }
            /*this.$API.patients.getPatients({customer: this.customerToShow.ID_Customer})
                .then(response => {
                    this.patients = response
                })*/
    this.searchCustomers.business = JSON.parse(localStorage.getItem("user")).businessid || ""
    this.searchCustomers.branch = JSON.parse(localStorage.getItem("user")).branch || ""
    this.getAllCustomers();
  },
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },
  methods: {
    closeAlert() {
      this.alert.show = false;

      
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletePatient") {
        this.checkBusniees(this.Confirmdata);
      }
      if (this.alert.options == "migratePatients") {
        this.migratePatients();
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    confirmDelete(data){
      this.showAlert('question','Eliminar' ,'¿Está seguro de eliminar este paciente?', 'deletePatient');
      this.Confirmdata = data;
    },
    checkBusniees(data) {

      JSON.parse(localStorage.getItem("user")).businesstype == "veterinary"
        ? this.rowRemoved(data)
        : this.rowRemoved2(data);
      this.rowRemoved2(data);
    },
    reloadData(payload) {
      this.$emit("reloadData", payload);
    },

    /*methods for bloodtestlaboratory*/
    logEvent() {},
    rowRemoved(event) {
      let patient_id = event.data.ID_Patient;
      this.$API.veterinarypatients
        .logicalDeletePatient(patient_id)
        .then(() => {
          this.$emit("reloadData");
          this.showAlert(
            "success",
            "Paciente eliminado",
            "El paciente ha sido eliminado correctamente"
          );
          this.getVeterinaryPatients();
          /*  this.$toasted.success(response.message) */
        })
        .catch((err) => {
          console.log(err);
          this.showAlert("danger", "Error", "Ocurrio un error al eliminar el paciente");
        });
    },
    rowRemoved2(event) {
      let patient_id = event.data.ID_Patient;
      this.$API.patients.logicalDeletePatient(patient_id)
        .then(() => {
        // console.log();
          this.$emit("reloadData");
          this.showAlert(
            "success",
            "Paciente eliminado",
            "El paciente ha sido eliminado correctamente"
          );
          this.getPatients();
          /*  this.$toasted.success(response.message) */
        })
        .catch((err) => {
          console.log(err);
          this.showAlert("danger", "Error", "Ocurrio un error al eliminar el paciente");
        });
      },
    dataSaved() {
      // console.log(this.patients)
    },
    addPatientToCustomer() {
      this.addPatient = true;
    },

    /*methods for veterinary*/
    getVeterinaryPatients() {
      this.search.customerID = this.customerToShow.ID_Customer;
      this.$API.veterinarypatients
        .getPatientsHistory({ customer: this.customerToShow.ID_Customer })
        .then((response) => {
          this.veterinarypatients = response;
        });
    },

    getPatients() {
      this.search.customerID = this.customerToShow.ID_Customer;
      this.$API.patients
        .getPatientsHistory({ customer: this.customerToShow.ID_Customer })
        .then((response) => {
          this.patients = response;
        });
    },

    addVeterinaryPatients() {
      this.$store
        .dispatch("deleteWindowByUuid", { uuid: this.win.uuid })
        .then(() => {
          this.$store.dispatch("addWindow", {
            name: "VeterinaryPatientAdd",
            component: "VeterinaryPatientAdd",
            unique: true,
            meta: { customerToShow: this.customerToShow },
          });
        });
    },

    addPatients() {
      this.$store
        .dispatch("deleteWindowByUuid", { uuid: this.win.uuid })
        .then(() => {
          this.$store.dispatch("addWindow", {
            name: "AddPatient",
            component: "AddPatient",
            unique: true,
            meta: { customerToShow: this.customerToShow },
          });
        });
    },

    patientSelected(data) {
      this.$store.dispatch("addWindow", {
        name: "patientData",
        component: "PatientData",
        unique: false,
        meta: { patient: data.data },
      });
    },

    veterinaryPatientSelected(data) {
      data.data.customer = this.customerToShow;
      this.$store.commit("vetPatientData/changeView", "tab-1");
      //this.commit("vetPatientData/changeView", "tab-1")
      this.$store.dispatch("addWindow", {
        name: "VeterinaryPatientData",
        component: "VeterinaryPatientData",
        unique: false,
        meta: { veterinarypatients: data.data, reload: true },
      });
    },
    filterHandlerPatients(response) {
      this.patients = response;
    },
    filterHandlerVeterinaryPatients(response) {
      this.veterinarypatients = response;
    },
    getAllCustomers() {
      this.$API.customers.getCustomersHistory(this.searchCustomers)
        .then(response => {
         // response = this.prepareCustomersResponse(response);
          this.customers = response;
         /*  console.log('customers all', response); */
        })
    },
    filterHandler(response) {
      this.customers = response;
    },
    handleShowCustomersList(type) {
      this.showCustomersList = true;
      this.customerToMigrate = {
        ID_Customer: null,
        type
      };
    },
    customerSelected(id) {      
      this.showCustomersList = false;
      this.customerToMigrate.ID_Customer = id;
      console.log(this.customerToMigrate);
      
      this.showAlert('question2','Migrar pacientes' ,'¿Está seguro de migrar los pacientes de este cliente?', 'migratePatients');
    },
    migratePatients() {
      if(this.customerToMigrate.type == 'veterinary') {
        this.$API.veterinarypatients.migratePatients({ newCustomer: this.customerToShow.ID_Customer , ID_Customer: this.customerToMigrate.ID_Customer })
        .then((res) => {
          console.log(res);
          this.showAlert('success','Migrar pacientes' , `Se migraron correctamente los pacientes del cliente: ${this.customerToMigrate.ID_Customer}`);
          this.getVeterinaryPatients();
          this.customerToMigrate = null;
        })
        .catch((err) => {
          console.log(err);
          this.showAlert('warning','Migrar pacientes' , `Ocurrio un error al migrar los pacientes del cliente: ${this.customerToMigrate.ID_Customer}`);
          this.customerToMigrate = null;
        })
      }
      if(this.customerToMigrate.type == 'medical') {
        this.$API.patients.migratePatients({ newCustomer: this.customerToShow.ID_Customer , ID_Customer: this.customerToMigrate.ID_Customer })
        .then((res) => {
          console.log(res);
          this.showAlert('success','Migrar pacientes' , `Se migraron correctamente los pacientes del cliente: ${this.customerToMigrate.ID_Customer}`);
          this.getPatients();
          this.customerToMigrate = null;
        })
        .catch((err) => {
          console.log(err);
          this.showAlert('warning','Migrar pacientes' , `Ocurrio un error al migrar los pacientes del cliente: ${this.customerToMigrate.ID_Customer}`);
          this.customerToMigrate = null;
        })
      }
    }
  },
};
</script>

<style scoped>
.btnsave {
  background-color: #00a178 !important;
  color: white;
}
.btnclean {
  background-color: #f29d35 !important;
  color: white;
}
</style>
