<template>
  <div>
    <v-row class="ma-0">
      <v-col cols="12" md="12" class="rounded-lg white">
        <div style="display: flex; justify-content: space-between">
          <h3 class="my-3">
            Servicios disponibles a utilizar: {{ pltcountbilled }}
          </h3>
        </div>
      </v-col>

      <v-col cols="12" class="rounded-lg white mt-3">
        <br />
        <h5>Por favor asigne un servicio al paciente marcando la casilla</h5>

        <GeneralFilter
          entity="lista"
          :filterEndpoint="$API.patients.getPatientsFilteredBilledList"
          :search="search"
          @emptyFilter="getPatients"
          @filtered="filterHandlerPatients"
        >
          <DxDataGrid class="isScrolledByGeneralFilter"
            id="gridContainer"
            :data-source="patients"
            :show-borders="true"
            key-expr="ID_Patient"
          >
            <DxColumn data-field="ID_Patient" caption="ID" />
            <DxColumn data-field="FirstName" caption="Nombre" />
            <DxColumn data-field="LastName" caption="Apellido" />
            <DxColumn
              data-field="ID_Patient"
              caption="Ver paciente"
              cell-template="showpatient"
              v-if="$store.getters['rolesUser/getCustomRole'](88)"
            >
            </DxColumn>
            <DxColumn
              cell-template="show-asigned"
              caption="Ver Asignados"
              v-if="$store.getters['rolesUser/getCustomRole'](87)"

            ></DxColumn>
            <DxColumn cell-template="show-template" 
            v-if="$store.getters['rolesUser/getCustomRole'](89)"
            
            caption=""></DxColumn>

            <!--
                      <DxColumn cell-template="show-template" caption="Test de Laboratorio"></DxColumn> -->

            <template #showpatient="{ data }">
              <a href="#" @click.prevent.stop="showPatient(data.data)">
                <v-icon color="primary">mdi-eye</v-icon>
              </a>
            </template>
            <template #show-asigned="{ data }">
              <v-btn class="primary" @click="openAsigned(data)">
                Ver Asignados
              </v-btn>
            </template>
            <template #show-template="{ data }">
              <v-btn
                class="primary"
                v-if="pltcountbilled > 0"
                @click="openModal(data)"
              >
                Asignar prueba
              </v-btn>
            </template>
          </DxDataGrid>
        </GeneralFilter>
      </v-col>
      <alerts
        v-if="alert.show"
        v-on:close_alert="closeAlert"
        v-on:accept_alert="acceptAlert"
        :properties="alert"
      >
      </alerts>
    </v-row>
    <Dialog
      :visible.sync="display"
      :containerStyle="{ width: '80vw' }"
      :position="'right'"
    >
      <div style="display: flex">
        <div style="flex: 50%">
          <h4>Ver Laboratorios Facturados</h4>

          <!-- Needs filter -->
          <DxDataGrid class="isScrolledByGeneralFilter"
            :data-source="labs"
            key-expr="ID"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="true"
          >
            <DxScrolling row-rendering-mode="virtual" />
            <DxPaging :page-size="5" />
            <DxPager
              :visible="true"
              :show-page-size-selector="false"
              :show-info="true"
              :show-navigation-buttons="true"
            />
            <DxColumn data-field="LaboratoryName" caption="Nombre"></DxColumn>
            <DxColumn
              data-field="LaboratoryNameBilling"
              caption="Nombre de facturación"
            ></DxColumn>
            <DxColumn
              data-field="LaboratoryTestDate"
              caption="Fecha examen"
            ></DxColumn>

            <DxColumn
              data-field="checked"
              caption="Asignar paciente"
              cell-template="budy"
            ></DxColumn>
            <DxColumn
              data-field="detail"
              caption="Detalle Laboratorio"
              cell-template="show"
            >
            </DxColumn>
            <template #show="{ data }">
              <a
                href="#"
                @click.prevent.stop="showDetails(data.data.ID_LaboratoryTest)"
              >
                <v-icon color="primary">mdi-eye</v-icon>
              </a>
            </template>
            <template #budy="{ data }">
              <input
                type="checkbox"
                id="checkbox"
                v-model="data.checked"
                @change="assignPatientLab(data)"
              />
              <!--  <DxCheckBox v-bind="data.checked" :v-model="data.checked"  @value-changed="assignPatientLab(data)"  /> -->
            </template>
          </DxDataGrid>

          <div class="options">
            <div class="options-container">
              <div class="option">
                <DxButton
                  text="Contained"
                  styling-mode="contained"
                  style="min-width: 120px"
                  :height="50"
                  @click="addAll()"
                  type="default"
                >
                  <template #content>
                    <span>
                      <DxLoadIndicator
                        :visible="loadIndicatorVisible"
                        class="button-indicator"
                      />
                      <span class="dx-button-text">Asignar Todos</span>
                    </span>
                  </template>
                </DxButton>
              </div>
              <div class="option">
                <DxButton
                  text="Contained"
                  styling-mode="contained"
                  style="min-width: 120px"
                  :height="50"
                  @click="saveAssign()"
                  type="success"
                >
                  <template #content>
                    <span>
                      <DxLoadIndicator
                        :visible="loadIndicatorVisible"
                        class="button-indicator"
                      />
                      <span class="dx-button-text">Asignar seleccionados</span>
                    </span>
                  </template>
                </DxButton>
              </div>

              &nbsp;
            </div>
          </div>
        </div>
        <div style="flex: 50%">
          <h4>Detalle de Laboratorio</h4>
          <!-- Needs filter -->
          <DxDataGrid class="isScrolledByGeneralFilter"
            :data-source="labDetails"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="true"
          >
            <DxScrolling row-rendering-mode="virtual" />
            <DxPaging :page-size="5" />
            <DxPager
              :visible="true"
              :show-page-size-selector="false"
              :show-info="true"
              :show-navigation-buttons="true"
            />
            <DxColumn data-field="ParameterOrder" caption="Orden"></DxColumn>
            <DxColumn data-field="ParameterName" caption="Nombre"></DxColumn>

            <DxColumn data-field="ParameterValue" caption="Valor"></DxColumn>
            <DxColumn data-field="ParameterUnit" caption="Unidad"></DxColumn>
            <DxColumn
              data-field="ParameterExpected"
              caption="Expectativa"
            ></DxColumn>
            <DxColumn data-field="ParameterType" caption="Tipo"></DxColumn>
          </DxDataGrid>
        </div>
      </div>

      <!--       <DataTable :value="labs">
                      <Column field="ID_LaboratoryTest" header="Laboratorio"></Column>
                      <Column field="LaboratoryTestDate" header="Fecha de examen"></Column>
                      <Column field="LaboratoryNameBilling" header="Tipo facturado"></Column>
                      <Column field="checked" header="paciente agregado">
                        <template #body="{ data }">
                           <InputSwitch v-model="data.checked" @change="assignPatientLab(data)" />

                        </template>
                      </Column>
                    </DataTable> -->
    </Dialog>

    <Dialog
      :visible.sync="displayHistory"
      :containerStyle="{ width: '80vw' }"
      :position="'right'"
    >
      <h4>Viendo Facturados</h4>

      <GeneralFilter
        entity="lista"
        :filterEndpoint="
          typebusiness == 'veterinary'
            ? $API.patients.getPatientAssignedvetFiltered
            : $API.patients.getPatientAssignedFiltered
        "
        :search="search"
        @emptyFilter="openAsigned"
        @filtered="filterHandlerpatientAssignedHistory"
      >
        <DxDataGrid class="isScrolledByGeneralFilter"
          :data-source="patientAssignedHistory"
          key-expr="ID"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="true"
        >
          <DxScrolling row-rendering-mode="virtual" />
          <DxPaging :page-size="5" />
          <DxPager
            :visible="true"
            :show-page-size-selector="false"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn data-field="LaboratoryName" caption="Nombre"></DxColumn>

          <DxColumn
            data-field="LaboratoryTestDate"
            caption="Fecha examen"
          ></DxColumn>

          <!--
                      <DxColumn data-field="checked" caption="Asignar paciente" cell-template="budy"></DxColumn>
                      <DxColumn data-field="detail" caption="Detalle Laboratorio" cell-template="show"> </DxColumn> -->
          <template #show="{ data }">
            <a
              href="#"
              @click.prevent.stop="showDetails(data.data.ID_LaboratoryTest)"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </a>
          </template>
          <template #budy="{ data }">
            <input
              type="checkbox"
              id="checkbox"
              v-model="data.checked"
              @change="assignPatientLab(data)"
            />
            <!--  <DxCheckBox v-bind="data.checked" :v-model="data.checked"  @value-changed="assignPatientLab(data)"  /> -->
          </template>
        </DxDataGrid>
      </GeneralFilter>
    </Dialog>
  </div>
</template>
<script>
/* import { DxCheckBox } from 'devextreme-vue/check-box'; */
import GeneralFilter from "../../../components/GeneralFilter";
import { DxButton } from "devextreme-vue/button";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import { DxScrolling, DxPaging, DxPager } from "devextreme-vue/data-grid";
import Dialog from "primevue/dialog";
/* import InputSwitch from 'primevue/inputswitch'; */
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
import Alerts from "../../../components/Alerts";

export default {
  name: "ManageCustomers",
  components: {
    DxDataGrid,
    DxColumn,
    Dialog,
    DxLoadIndicator,
    DxButton,
    Alerts,
    /*     DxCheckBox, */
    DxScrolling,
    DxPaging,
    DxPager,
    GeneralFilter,
  },
  props: ["customerToShow", "win"],
  data() {
    return {
      typebusiness: "",
      midialog: 0,
      loadIndicatorVisible: false,
      display: false,
      displayHistory: false,
      assignAll: false,
      assignedLabs: [],
      listLabs: [],
      patients: [],
      labDetails: [],
      patientAssignedHistory: [],
      selectedPatient: undefined,
      labs: [],
      pltcountbilled: 0,
      alert: {
        type: null,
        show: false,
        header: "",
        body: "",
      },
      search: {
        filter: null,
      },
    };
  },
  mounted() {
    this.typebusiness =
      JSON.parse(localStorage.getItem("user")).businesstype || "";

    if (this.typebusiness == "veterinary") {
      this.getVeterinaryPatients();
      this.getLabsvet();
      this.getInfoBilledvet();
      this.getCustomerLabsvet();
    } else {
      this.getLabs();
      this.getPatients();
      this.getInfoBilled();
      this.getCustomerLabs();
    }
  },
  /*   watch: {
      labs: {
        deep: true,
        handler: (nuevoValor, valorAnterior) => {
          console.log("labs era", valorAnterior, " y ahora es", nuevoValor);
        }
      }
    }, */

  methods: {
    showPatient(data) {
      this.$store
        .dispatch("deleteWindowByUuid", { uuid: this.win.uuid })
        .then(() => {
          this.$store.dispatch("addWindow", {
            name: "patientData",
            component: "PatientData",
            unique: false,
            meta: { patient: data },
          });
          this.$store.commit("patientData/changeView", "tab-2");
        });
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    addAll() {
      this.labs.forEach((lab) => {
        this.assignedLabs.push(lab);
      });
      this.saveAssign();
    },
    closeAlert() {
      this.alert.show = false;
    },
    acceptAlert() {},
    showDetails(id) {
      console.log(id);
      this.$API.customers.getParameters(id).then((response) => {
        this.labDetails = response;
      });
    },
    getPatients() {
      this.search.ID_Customer = this.customerToShow.ID_Customer;
      this.$API.patients
        .getPatientsHistory({ customer: this.customerToShow.ID_Customer })
        .then((response) => {
          this.patients = response;
        });
    },
    getVeterinaryPatients() {
      this.$API.veterinarypatients
        .getPatients({ customer: this.customerToShow.ID_Customer })
        .then((response) => {
          this.patients = response;
        });
    },
    getLabs() {
      this.$API.customers
        .getLabs()
        .then((response) => {
          this.listLabs = response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLabsvet() {
      this.$API.customers
        .getLabsvet()
        .then((response) => {
          this.listLabs = response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCustomerLabsvet() {
      this.$API.customers
        .getCustomerLabsvet(this.customerToShow.ID_Customer)
        .then((response) => {
          /* console.log("estos son los getlabs ",this.listLabs); */
          /*  response.forEach(clab => {
           this.listLabs.forEach(llab => {

             if (clab.ID_LaboratoryTest == llab.ID_LaboratoryTest) {

               if (clab.LaboratoryNameBilling) {
                 clab.LaboratoryNameBilling = llab.LaboratoryNameBilling
               } else {
                 clab['LaboratoryNameBilling'] = llab.LaboratoryNameBilling
               }
               if (clab.LaboratoryName) {
                 clab.LaboratoryName = llab.LaboratoryName
               } else {
                 clab['LaboratoryName'] = llab.LaboratoryName
               }


             }
           });
         }); */
          this.labs = response;
          console.log("estos son los labs", this.labs);
        })
        .catch(() => {
          this.$toasted.danger(
            "Ocurrio un problema al obtener laboratorios facturados para paciente."
          );
        });
    },
    getCustomerLabs() {
      this.$API.customers
        .getCustomerLabs(this.customerToShow.ID_Customer)
        .then((response) => {
          /* console.log("estos son los getlabs ",this.listLabs); */
          /*  response.forEach(clab => {
           this.listLabs.forEach(llab => {

             if (clab.ID_LaboratoryTest == llab.ID_LaboratoryTest) {

               if (clab.LaboratoryNameBilling) {
                 clab.LaboratoryNameBilling = llab.LaboratoryNameBilling
               } else {
                 clab['LaboratoryNameBilling'] = llab.LaboratoryNameBilling
               }
               if (clab.LaboratoryName) {
                 clab.LaboratoryName = llab.LaboratoryName
               } else {
                 clab['LaboratoryName'] = llab.LaboratoryName
               }


             }
           });
         }); */
          this.labs = response;
          console.log("estos son los labs", this.labs);
        })
        .catch(() => {
          this.$toasted.danger(
            "Ocurrio un problema al obtener laboratorios facturados para paciente."
          );
        });
    },
    assignPatientLab(data) {
      let isIncluded =
        this.assignedLabs.find((e) => e.ID === data.data.ID) !== undefined;
      if (!isIncluded) {
        this.assignedLabs.push(data.data);
      } else {
        this.assignedLabs = this.assignedLabs.filter(
          (lab) => lab.ID != data.data.ID
        );
      }
    },

    saveAssign(/* data */) {
      console.log("asigned labs", this.assignedLabs);
      this.loadIndicatorVisible = true;
      let errRequest = false;
      this.assignedLabs.forEach((data) => {
        if (this.typebusiness == "veterinary") {
          this.$API.customers
            .updateCustomerLabvet(this.selectedPatient.data.ID_Patient, data.ID)
            // eslint-disable-next-line no-unused-vars
            .then((_response) => {
              // eslint-disable-next-line no-unused-vars
            })
            .catch((err) => {
              console.log(err)
              errRequest = true;
            });
        } else {
          this.$API.customers
            .updateCustomerLab(this.selectedPatient.data.ID_Patient, data.ID)
            // eslint-disable-next-line no-unused-vars
            .then((_response) => {
              // eslint-disable-next-line no-unused-vars
            })
            .catch((err) => {
              console.log(err)
              errRequest = true;
            });
        }
      });
      if (!errRequest) {
        this.display = false;
        this.assignedLabs = [];
        if (this.typebusiness == "veterinary") {
          this.getVeterinaryPatients();
          this.getLabsvet();
          this.getInfoBilledvet();
          this.getCustomerLabsvet();
        } else {
          this.getLabs();
          this.getPatients();
          this.getInfoBilled();
          this.getCustomerLabs();
        }
        this.loadIndicatorVisible = false;
        this.showAlert(
          "success",
          "exito",
          "Se le agrego un servicio de test de laboratorio al paciente"
        );
      } else {
        (this.loadIndicatorVisible = false),
          this.showAlert(
            "danger",
            "error",
            "Ocurrio un problema al intentar guardar, por favor revisar"
          );
        this.display = false;
      }
    },
    getInfoBilled() {
      this.$API.customers
        .getInfoBilled({ ID_Customer: this.customerToShow.ID_Customer })
        .then((response) => {
          this.pltcountbilled = response[0].BilledServices;
        });
    },
    getInfoBilledvet() {
      this.$API.customers
        .getInfoBilledvet({ ID_Customer: this.customerToShow.ID_Customer })
        .then((response) => {
          console.log("response billed vet", response);
          this.pltcountbilled = response[0].BilledServices;
        });
    },
    openModal(data) {
      this.selectedPatient = data;
      this.display = true;
    },
    openAsigned(data) {
      if (data != undefined) {
        this.openAsignedBilled = data.data;
        console.log("esto es:", this.openAsignedBilled);
      }
      this.search.openAsignedBilled = this.openAsignedBilled;
      this.displayHistory = true;
      if (this.typebusiness == "veterinary") {
        this.$API.patients
          .getPatientAssignedvetHistory(this.openAsignedBilled)
          .then((response) => {
            this.patientAssignedHistory = response;
            this.patientAssignedHistory.map((assigned) => {
              this.listLabs.forEach((lab) => {
                if (lab.ID_LaboratoryTest == assigned.ID_LaboratoryTest) {
                  assigned["LaboratoryName"] = lab.LaboratoryName;
                }
              });
            });
          })
          .catch(() => {
            this.$toasted.danger("Ocurrio un problema, por favor revisar");
          });
      } else {
        this.$API.patients
          .getPatientAssignedHistory(this.openAsignedBilled)
          .then((response) => {
            this.patientAssignedHistory = response;
            this.patientAssignedHistory.map((assigned) => {
              this.listLabs.forEach((lab) => {
                if (lab.ID_LaboratoryTest == assigned.ID_LaboratoryTest) {
                  assigned["LaboratoryName"] = lab.LaboratoryName;
                }
              });
            });
          })
          .catch(() => {
            this.$toasted.danger("Ocurrio un problema, por favor revisar");
          });
      }
    },
    assignLabTest(data) {
      console.log(data);
      this.$API.customers
        .updatePltBilled({
          ID_Customer: this.customerToShow.ID_Customer,
          ID_Patient: data.data.ID_Patient,
        })
        .then(() => {
          this.getInfoBilled();
          this.$toasted.success(
            "Se le agrego un servicio de test de laboratorio al paciente"
          );
        })
        .catch(() => {
          this.$toasted.danger("Ocurrio un problema, por favor revisar");
        });
    },
    filterHandlerPatients(response) {
      this.patients = response;
    },
    filterHandlerpatientAssignedHistory(response) {
      this.displayHistory = true;
      if (this.typebusiness == "veterinary") {
        this.patientAssignedHistory = response.map((assigned) => {
          this.listLabs.forEach((lab) => {
            if (lab.ID_LaboratoryTest == assigned.ID_LaboratoryTest) {
              assigned["LaboratoryName"] = lab.LaboratoryName;
            }
          });
        });
      } else {
        this.patientAssignedHistory = response;
        this.patientAssignedHistory.map((assigned) => {
          this.listLabs.forEach((lab) => {
            if (lab.ID_LaboratoryTest == assigned.ID_LaboratoryTest) {
              assigned["LaboratoryName"] = lab.LaboratoryName;
            }
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;
  padding: 0.5rem;
  border-radius: 15px;
  margin: 0.5rem;
}

div.p-hidden-accesible {
  display: none !important;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.options-container .option:last-child {
  float: right;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  min-width: 24%;
  display: inline-block;
  margin: 10px;
}

.options-container {
  display: flex;
  align-items: flex-start;
}

#small-indicator,
#medium-indicator,
#large-indicator {
  vertical-align: middle;
  margin-right: 10px;
}

#button,
#button .dx-button-content {
  padding: 0;
}

#button .button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.indicators {
  display: flex;
  align-items: center;
}
</style>
