<template>
    <v-expansion-panel>
        <v-expansion-panel-header class="mt-2">
    
    CLIENTE: {{ isBusinessOrOngOrPublicEntity ? customerToShow.BusinessName : customerToShow.FirstName + ' ' + customerToShow.LastName }}
  
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-tabs
                    v-model="tab"
                    dark background-color="primary"
                    icons-and-text
            >
                <v-tabs-slider color="red"></v-tabs-slider>

                <v-tab href="#tab-1"  @click="changeTab('tab-1')">
                    GESTIONAR CLIENTE
                    <v-icon>mdi-account-box</v-icon>
                </v-tab>

                <v-tab href="#tab-5" v-if="$store.getters['rolesUser/getCustomRole'](79)"  @click="changeTab('tab-1')">
                    CUENTAS POR COBRAR
                    <v-icon>mdi-account-cash-outline</v-icon>
                </v-tab>

                <v-tab 
                v-if="$store.getters['rolesUser/getCustomRole'](82) &&
                typebusiness!='accounting_and_bill'"
                href="#tab-2"  @click="changeTab('tab-2')">
                    PACIENTES
                    <v-icon>mdi-message-question-outline</v-icon>
                </v-tab>

                <v-tab  v-if="$store.getters['rolesUser/getCustomRole'](86) &&
                (typebusiness!='accounting_and_bill' && typebusiness!='veterinary')" href="#tab-3"  @click="changeTab('tab-3')" >
                    SERVICIOS FACTURADOS
                    <v-icon>mdi-message-question-outline</v-icon>
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
                <v-tab-item value="tab-1">
                    <v-card flat class="pa-1 rounded-lg">
                        <v-card-text class="white" :key="reloadmanagecustomer">
                            <manage-customers

                            :key="reloadKey"
                            :customerToShow="customerToShow" :win="win"></manage-customers>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-2">
                    <v-card flat class="pa-1 rounded-lg">
                        <v-card-text  class="white">
                            <patients :key="reloadpatientofcustomer" :customerToShow="customerToShow" :win="win"
                            ></patients>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-3">
                    <v-card flat>
                        <v-card-text :key="reloadserviceofcustomer" class="pa-1 rounded-lg">
                            <billed-services :key="reloadKey" :customerToShow="customerToShow" :win="win"></billed-services>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-4">
                    <v-card flat class="pa-1 rounded-lg">
                        <v-card-text class="white">
                            <credits :key="reloadKey"></credits>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

                <v-tab-item value="tab-5">
                    <v-card flat class="pa-1 rounded-lg">
                        <v-card-text class="white">
                            <AccountsReceivableList :win="win" :key="reloadKey"></AccountsReceivableList>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

            </v-tabs-items>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>


<script>

    import ManageCustomers from "./ManageCustomers";
    import Patients from "./Patients";
    import Credits from "./Credits";
    import BilledServices from "./BilledServices"
    import AccountsReceivableList from "./AccountsReceivableList"
    import { mapState } from "vuex";


    export default {
        name: "CustomerData",
        props: ['win'],

        data() {
            return {
                tab: null,
                customerToShow: null,
                reloadKey: 0,
                typebusiness:null,
            }
        },
        computed: {
            isBusinessOrOngOrPublicEntity() {
                if(this.customerToShow){
                    return this.customerToShow.Is_a_Business || this.customerToShow.Is_a_Ong || this.customerToShow.Is_a_PublicEntity;
                }
                else {
                    return 0
                }  
            },
            ...mapState('customerData', {
                tabState: state => state.currentView,
                reloadmanagecustomer: state => state.reloaders.reloadmanagecustomer,
                reloadserviceofcustomer: state => state.reloaders.reloadserviceofcustomer,
                reloadpatientofcustomer: state => state.reloaders.reloadpatientofcustomer
            }),
    
            ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      })

        },
        watch: {
        tabState() {
            this.tab = this.tabState
        },
        },
        components: {
            Credits,
            Patients,
            ManageCustomers,
            BilledServices,
            AccountsReceivableList
        },
        mounted() {
            this.$store.dispatch('rolesUser/getAllRoles')

          this.typebusiness = JSON.parse(localStorage.getItem("user")).businesstype || ""
            if(this.win.meta.userNew){
                let search = {}
                search.business = JSON.parse(localStorage.getItem("user")).businessid || ""
                search.branch = JSON.parse(localStorage.getItem("user")).branch || ""
                this.$API.customers.getCustomers(search)
                    .then(response => {
                        let data =  response
                        //console.log(this.win.meta.customerToShow.customer);
                        //console.log(data);
                         data.forEach(element => {
                           // console.log(element)

                            if (element.ID_Customer == this.win.meta.customerToShow.customer) {
                                this.customerToShow = element;
                            }
                        });
                    })
               // console.log("new user")
            }
            else{
            this.customerToShow = this.win.meta.customerToShow;
            }



           // console.log(this.win.meta.customerToShow)
        },
        methods: {
            reloadData(payload) {
                this.$emit('reloadData', payload)
            },
            reload(){
               this.reloadKey++;
                this.$store.commit('customerData/setReloaders','reloadpatientofcustomer')

            },
            changeTab(tab){
                this.$store.commit('customerData/changeView', tab)
            }

        },
        
        

    }
</script>

<style scoped>
.databox{
    padding: 16px;
    border-radius: 15px;

}
</style>
